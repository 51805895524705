export default {
    en: {
      navigation: {
        menu: ["About", "Classes", "Contact"]
      },
      hero: {
        titleH1: "Miša Yoga"
      },
      about: {
        titleH2: "About Miša Mladenović",
        p1:
          "Miša Mladenović was born in Belgrade in a family of athletes that dedicated most of their life to sports. Prolific in sport from his early days, he explored  martial arts and acrobatics(capoeira, karate, kung fu   and gymnastics.) Falling in love with Yoga happened while studying sports on the faculty of sport and physical education. Since that time Misa is an avid explored of yogic disciplines.",
        p2:
          "Miša obtained his certification as Yoga Teacher (Hatha and Ashtanga vinyasa yoga)  from Rishikul yoga shala in Rishikesh and his certification as an Aerial yoga instructor from Fl'air Studios in Berlin. Misa strives to continuously improve his skills and guide his students safely by using Yoga in conjunction with gymnastic strength drills and contortion principles of flexibility."
      },
      schedule: {
        titleH2: "Classes",
        locations: [
          {
            location: `Berlin`,
            id: "berlin",
            address: "Fl'air studios - Holzmarktstraße 15-18 S-Bahnbogen 52",
            days: [
              { Monday: "" },
              { Tuesday: "" },
              { Wednesday: "20:30 CET" },
              { Thursday: "" },
              { Friday: "" },
              { Saturday: "" },
              { Sunday: "" }
            ],
            map:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5610.546707951766!2d13.417082935815491!3d52.51412952215861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84e3c7f848c9f%3A0xa9654cc8f3e1e976!2sHolzmarktstra%C3%9Fe%2015-18%2C%2010179%20Berlin%2C%20Germany!5e0!3m2!1sen!2sit!4v1628274080004!5m2!1sen!2sit",
            price: "25e per class"
          },
          {
            location: "Online",
            id: "online",
            address: "Zoom",
            days: [
              { Monday: "18:00 CET" },
              { Tuesday: "" },
              { Wednesday: "18:00 CET" },
              { Donnerstag: "" },
              { Friday: "18:00 CET" },
              { Saturday: "" },
              { Sunday: "" }
            ],
            map:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21428012.439156853!2d6.335490897087229!3d49.034852816779924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ed8886cfadda85%3A0x72ef99e6b3fcf079!2sEurope!5e0!3m2!1sen!2sit!4v1609068853705!5m2!1sen!2sit",
            price: "25e per class"
          }
        ]
      },
      contact: {
        titleH2: "Contact"
      },
      footer: {
        paragraph: "Copyright © 2021 Misa Yoga. All rights reserved."
      }
    },
    de: {
      navigation: {
        menu: ["Über", "Klassen", "Kontakt"]
      },
      hero: {
        titleH1: "Miša Yoga"
      },
      about: {
        titleH2: "Über Miša Mladenović",
        p1:
          "Miša Mladenović wurde in Belgrad in einer Familie von Sportlern geboren, die den größten Teil ihres Lebens dem Sport gewidmet haben. Schon in jungen Jahren trieb er viel Sport und beschäftigte sich mit Kampfsportarten und Akrobatik (Capoeira, Karate, Kung Fu und Gymnastik). Während seines Sportstudiums an der Fakultät für Sport und Leibeserziehung verliebte er sich in Yoga. Seit dieser Zeit ist Misa ein begeisterter Erforscher der yogischen Disziplinen.",
        p2:
          "Miša erhielt seine Zertifizierung als Yogalehrer (Hatha und Ashtanga Vinyasa Yoga) von der Rishikul Yoga Shala in Rishikesh und seine Zertifizierung als Aerial Yoga Instructor von den Fl'air Studios in Berlin. Misa ist bestrebt, seine Fähigkeiten ständig zu verbessern und seine Schüler sicher anzuleiten, indem er Yoga in Verbindung mit gymnastischen Kraftübungen und Kontorsionsprinzipien der Flexibilität einsetzt."
      },
      schedule: {
        titleH2: "Klassen",
        locations: [
          {
            location: "Berlin",
            id: "berlin",
            address: "Fl'air studios - Holzmarktstraße 15-18 S-Bahnbogen 52",
            days: [
              { Montag: "" },
              { Dienstag: "" },
              { Mittwoch: "20:30 CET" },
              { Donnerstag: "" },
              { Freitag: "" },
              { Samstag: "" },
              { Sonntag: "" }
            ],
            map:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5610.546707951766!2d13.417082935815491!3d52.51412952215861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84e3c7f848c9f%3A0xa9654cc8f3e1e976!2sHolzmarktstra%C3%9Fe%2015-18%2C%2010179%20Berlin%2C%20Germany!5e0!3m2!1sen!2sit!4v1628274080004!5m2!1sen!2sit",
            price: "25e per class"
          },
          {
            location: "Online",
            id: "online",
            address: "Zoom",
            days: [
              { Montag: "18:00 CET" },
              { Dienstag: "" },
              { Mittwoch: "18:00 CET" },
              { Donnerstag: "" },
              { Freitag: "18:00 CET" },
              { Samstag: "" },
              { Sonntag: "" }
            ],
            map:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21428012.439156853!2d6.335490897087229!3d49.034852816779924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ed8886cfadda85%3A0x72ef99e6b3fcf079!2sEurope!5e0!3m2!1sen!2sit!4v1609068853705!5m2!1sen!2sit",
            price: "25e per class"
          }
        ]
      },
      contact: {
        titleH2: "Kontakt"
      },
      footer: {
        paragraph: "Copyright © 2021 Misa Yoga. All rights reserved."
      }
    }
  };
  