// import logo from './logo.svg';
import React, { useState } from "react";
import "./reset.css";
import "./styles.css";
import Copy from "./assets/copy/Translations.js";
import Hero from "./sections/1Hero/Hero";
import About from "./sections/2About/About";
import Schedule from "./sections/3Schedule/Schedule";
import Contact from "./sections/4Contact/Contact";
import Footer from "./sections/5Footer/Footer";

export default function App() {
  const [translations, setTranslations] = useState(Copy["en"]);
  const changeLanguage = (lang) => {
    setTranslations(Copy[lang]);
  };

  return (
    <div className="App">
      <Hero text={translations} changeLanguage={changeLanguage} />
      <About text={translations} />
      <Schedule text={translations} />
      <Contact text={translations} />
      <Footer />
    </div>
  );
}
