import React, { useState } from "react";
import styles from "./ClassesView.module.css";
import Iframe from "react-iframe";

export default function ClassesView({ classes }) {
  const [selectedClass, setSelectedClass] = useState(classes.locations[0]);
  const handleClassSelection = (userPick) => () => {
    setSelectedClass(userPick);
  };

  return (
    <div className={styles.container}>
      <div className={styles.selection}>
        {classes.locations.map((s, i) => {
          return (
            <div key={s.id} onClick={handleClassSelection(s)}>
              {s.location}
            </div>
          );
        })}
      </div>
      <div className={styles.dualContainer}>
        <div>
          <h3 className={styles.titleH3}>{selectedClass.location}</h3>
          <br />
          <p>{selectedClass.address}</p>
          <br />
          <br />
          {selectedClass.days.map((d, i) =>
            d[Object.keys(d)] !== "" ? (
              <p key={i}>
                {Object.keys(d)}: {d[Object.keys(d)]}
              </p>
            ) : null
          )}
        </div>
        <div>
          <Iframe
            url={selectedClass.map}
            width="100%"
            height="350px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          /> 
        </div>
      </div>
    </div>
  );
}
