import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styles from "./Navigation.module.css";
import EN from "./../../assets/images/misayoga-en.png";
import DE from "./../../assets/images/misayoga-de.png";

const menuIcon = <FontAwesomeIcon icon={faBars} />;

export default function Navigation({ open, text, changeLanguage }) {
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleChangeLanguage = (lang) => {
    return () => {
      changeLanguage(lang);
    };
  };
  return (
    <header className={styles.header}>
      {!menuOpen ? null : (
        <ul className={styles.languagesContainer}>
          <li onClick={handleChangeLanguage("en")}>
            <img className={styles.languageIcons} src={EN} alt="english" />
          </li>
          <li onClick={handleChangeLanguage("de")}>
            <img className={styles.languageIcons} src={DE} alt="german" />
          </li>
        </ul>
      )}

      <div onClick={toggleMenu} className={styles.menuIcon} open={open}>
        {menuIcon}
      </div>
      {menuOpen ? (
        <nav className={styles.nav}>
          <ul className={styles.navUl}>
            {text.navigation.menu.map((navItem, i) => {
              return (
                <li key={navItem}>
                  <a href={`#${navItem}`}>{navItem}</a>
                </li>
              );
            })}
            <li onClick={toggleMenu} className={styles.close}>
              &#10005;
            </li>
          </ul>
        </nav>
      ) : null}
    </header>
  );
}
