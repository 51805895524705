import React from "react";
import TitleH2 from "./../../ui/TitleH2/TitleH2";
import ClassesView from "./../../ui/ClassesView/ClassesView";
import styles from "./Schedule.module.css";
import Misaskipper from "../../assets/images/misa8-min.JPG";

export default function Schedule({ text }) {
  return (
    <section id={text.navigation.menu[1]} className={styles.container}>
      <TitleH2 title={text.schedule.titleH2} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={Misaskipper} alt="" />
      </div>
      <ClassesView classes={text.schedule} />
    </section>
  );
}
