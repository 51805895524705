import React from "react";
import TitleH1 from "./../../ui/TitleH1/TitleH1";
import Navigation from "./../../ui/Navigation/Navigation";
import styles from "./Hero.module.css";
import Misahero from "../../assets/images/UYhero-min.jpg";

export default function Hero({ text, changeLanguage }) {
  return (
    <section className={styles.container}>
      <Navigation text={text} changeLanguage={changeLanguage} />
      <TitleH1 />
      <img className={styles.heroImg} src={Misahero} alt="hero" />
    </section>
  );
}
