import React from "react";
import TitleH2 from "./../../ui/TitleH2/TitleH2";
import styles from "./About.module.css";
import Misatree from "./../../assets/images/misagif.gif";
import Misamaster from "./../../assets/images/misa3-min.jpg";
import Misacertificate from "./../../assets/images/misa6-min.jpg";

export default function About({ text }) {
  return (
    <section id={text.navigation.menu[0]} className={styles.container}>
      <TitleH2 title={text.about.titleH2} />
      <div className={styles.flexContainer}>
        <div className={styles.flexChildren}>
          <img loading="lazy" className={styles.imgVertical} src={Misatree} alt="" />
        </div>
        <div className={styles.flexChildren}>
          <p className={styles.paragraph}>{text.about.p1}</p>
        </div>
      </div>
      <div>
        <img loading="lazy" className={styles.imgHorizontal} src={Misamaster} alt="" />
      </div>
      <div className={styles.flexMarginMinus}>
        <div className={styles.flexChildren}>
          <p className={styles.paragraph}>{text.about.p2}</p>
        </div>
        <div className={styles.flexChildren}>
          <img loading="lazy" className={styles.imgVertical} src={Misacertificate} alt="" />
        </div>
      </div>
    </section>
  );
}
